.table-container{
   /* padding: 3em; 
   padding-right: 5vw; */
   border: #505050 solid;
   border-radius: 15px;
   margin-bottom: 3em;
   margin-right: 3em;
   margin-left: 3em;
}

.table-container h1{
    /* font-family: 'poker'; */
    font-family:  Verdana, sans-serif;
    color: white; 
    text-align: center;
    margin-bottom: 20px;
    background-color: #1a1a1a;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 30px;
    padding: 10px;

}

.timeTable{
    padding-left: 1em;
    padding-right: 1em;
}

.leaderboards-container{
    padding-right:10em;
    padding-left: 10em;
    padding-bottom: 10em;
}

.assign-memebrship-text{
    /* padding:3em; */
    border: #505050 solid;
    border-radius: 15px;
    margin-bottom: 5vh;
}

.assign-memebrship-text em{
    font-family: 'Roboto', sans-serif;
    color: rgb(201, 3, 3);
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
}

.assign-memebrship-text h1{
    /* font-family: 'poker'; */
    font-family:  Verdana, sans-serif;
    color: white; 
    text-align: center;
    margin-bottom: 50px;
    background-color: #1a1a1a;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 30px;
    padding: 10px;
}

.assign-memebrship-text p{
    text-align: center;
    /* font-family: 'poker'; */
    font-family:  Verdana, sans-serif;
    font-size: 20px;
    font-weight: 400;
    /* padding-left: 10vh; */
    color: white;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;
}

.mebershipSearch{
    /* border: 1px solid black; */
    margin: 1rem;
    padding: 2rem 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

.uploadMembership{
    margin: 1rem;
    padding: 2rem 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

.membershipNumber{
    border: solid #505050; 
    border-radius: 5px;
    background-color: white;
    width: 15vh;
    height: auto;
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: middle;
    margin-left: 2vw;
}

.usernameInput{
    border: solid #505050; 
    border-radius: 5px;
    width: 10vw;
    height: auto;
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: middle;
    margin-left: 1vw;
}

.membershipButton{
    font-size: large;
    background-color: #af0000; 
}

.usernameButtons{

}

.buttonText{
    color: white;
}

.row{
    color: white !important;
}

th {
    color: rgb(255, 255, 255);
}

.custom-select{
    padding-right: 20px !important;
}

@media screen and (max-width: 1050px) {
    .assign-memebrship-text p{
        /* padding-left: 0 !important; */
    }

    .usernameInput{
        width: 70%;
    }

    .uploadMembership{
        display: block;
    }
}

@media screen and (max-width: 650px) {
    .assign-memebrship-text p{
        /* padding-left: 0 !important; */
    }

    .usernameInput{
        width: 100%;
    }

    .uploadMembership{
        display: block;
    }
}
  
@media screen and (max-width: 655px) {
    .table-container{
        margin-bottom: 0.5em !important;
        margin-right: 0.5em !important;
        margin-left: 0.5em !important;
        padding: 0.1em;
    }

    .assign-memebrship-text h1{
        font-size: 20px;
        margin-bottom: 20px;
    }
    
    .assign-memebrship-text p{
        font-size: 15px;
    }

    .table-container h1{
        font-size: 20px;
    }

    .assign-memebrship-text em{
        font-size: 18px;
    }
}