.TermsAndConditionsContainer{
    color: white;
    margin-top: 2%;
    font-size: 15px;
    width: 95%;
}

.TermsAndConditionsContainer h1{
    color: white;
    margin-top: 2%;
    font-size: 20px;
}