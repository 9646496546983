.prizeWinnings{
    border: solid #505050;
    border-radius: 5px;
    background-color: white;
    width: 15vh;
    height: 60px;
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: middle;
    margin-left: 2vw;
}

.page-sections-prize{
    height: 100%;
    width: auto;
}

.payout-mobile{
    display: none;
}

.payout-desktop{
    display: block;
}

.prize-container{
    /* padding: 1em; */
    padding-top: 1em;
    justify-content: center;
    text-align: center;
    /* display: flex; */
    border: solid #505050;
    border-radius: 20px;
    background-color: #1a1a1a;
    margin-bottom: 50px;
}

.prize-container h1{
    /* font-family:  Verdana, sans-serif; */
    font-family: 'Roboto', sans-serif;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    background-color: #1a1a1a;
}

.left-prize{
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    padding-right: 15%;
    padding-left: 15%;
}

.left-prize h1{
    color: white;
    font-size: 30px;
}

.right-prize{
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    padding-right: 13%;
    padding-left: 13%;
}

.claim-prize-text{
    /* padding:3em; */
    border: #505050 solid;
    border-radius: 15px;
    margin-bottom: 3em;
    margin-right: 3em;
    margin-left: 3em;
    padding-bottom: 3em;
    /* margin-top: 60px; */
}

.claim-prize-text h1{
    /* font-family:  Verdana, sans-serif; */
    font-family: 'Roboto', sans-serif;
    color: white;
    text-align: center;
    margin-bottom: 50px;
    background-color: #1a1a1a;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 30px;
    padding: 10px;
}

.claim-prize-text p{
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-weight: 400;
    padding-right: 3em;
    padding-left: 3em;
    color: white;
}

.prizes-search{
    margin-bottom: 1rem;
    padding: 2rem 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

.img-container-payouts{
    position: relative;
    justify-content: center;
    display: flex;
    /* margin-bottom: 50px; */
    /* width: 30vw; */
    /* background-color: #1a1a1a; */
    border-radius: 20px;
    padding: 1em;
}

.img-container-payouts img{
    position:relative;
    width: 100%;
    height: auto;
    /* border: solid #505050;  */
    /* border-radius: 20px; */
}

.winningsButton{
    height: 53px;
    font-size: large;
    background-color: #af0000;
}

@media screen and (max-width:400px) {
    .claim-prize-text p{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

}

@media screen and (max-width:655px) {
    .claim-prize-text h1{
        font-size: 20px !important;
        margin-bottom: 20px;
    }

    .claim-prize-text p{
        font-size: 15px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .left-prize h1{
        font-size: 30px;
    }

    .image-header-mobile{
        display: block !important;
    }

    .image-header-pc{
        display: none;
    }

    .image-header2{
        padding-top: 15PX;
    }
}

@media screen and (max-width: 850px) {
    .img-container-payouts img{
        /* width: 250px; */
    }

    .winningsButton{
        height: 60px;
    }

    .prizeWinnings{
        height: 70px;
    }

    .image-header2{
        width:100%;
        height:auto !important;
        /* margin-top: 50px; */
        /* padding-top: 15PX; */
    }

    .claim-prize-text{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .payout-mobile{
        display: block;
        border-radius: 20px;
    }

    .payout-desktop{
        display: none;
    }

    .left-prize{
        padding-right: 2%;
        padding-left: 2%;
    }

    .right-prize{
        padding-right: 2%;
        padding-left: 2%;
    }
}

.image-header2{
    width:100%;
    /* height:250px; */
}

.image-header2 img{
    width:100%;
    height:auto;
}

.image-header-mobile{
    display: none;
}
