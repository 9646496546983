@font-face {
    font-family: 'poker';
    src: local("poker"),
    url("../../fonts/PokerKingsRegular-36K8.ttf") format("truetype");
      /* url("../../fonts/SpaceQuestItalic-60Rx.ttf") format("truetype"); */
  }

@media screen and (max-width:655px) {
    .background video{
        height: 100% !important;
        width: 100% !important;
    }

    .logo-container{
        left: 0% !important;
        width: 100% !important;
    }

    .logo-container2{
        left: 0% !important;
        width: 100% !important;
    }

    .logo-container p{
        color: rgb(233, 233, 233);
        text-align: center;
        font-size: 15px !important;
        width:100% !important;
    }

    .background-pc{
        display: none !important;
    }

    .verifyCheckbox{
        cursor: pointer;
        width: 40px !important;
        height: 40px;
        margin-right: 30px;
        margin-top: 5px;
    }
    
    .verifiyContainer{
        justify-content: unset !important;
        margin-left: 5px;
    }
    
    
    .verifiyContainer a{
        color: white;
    }

    .verifyCheckbox2{
        cursor: pointer;
        width: 40px !important;
        height: 40px;
        margin-right: 30px;
        margin-top: 5px;
    }
    
    .verifiyContainer2{
        justify-content: unset !important;
    }
    
    
    .verifiyContainer2 a{
        color: white;
    }
}

@media screen and (max-width: 850px)  and (min-width: 655px){
    .logo-container p {
        font-size: 20px !important;
    }

    .logo-container{
        width: 70vw !important;
        left: 15% !important;
    }

    .background-pc{
        display: none !important;
    }
}

@media screen and (max-width: 1300px) and (min-width: 850px){
    .logo-container p {
        font-size: 2vh !important;
    }

    .logo-container{
        /* width: 0vw !important;
        left: 20% !important; */
        padding-left: 10px;
    }

    .background-pc{
        display: none !important;
    }

}

@media screen and (min-width: 850px){

    .logo-container2{
        display: none !important;
    }

    .promo-container{
        display: none !important;
    }

    .background-mobile{
        display: none;
    }
}

.verifyCheckbox{
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 30px;
    margin-top: 5px;
}

.verifiyContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; 
    margin-left: 20%;*/
    margin-bottom: 10px;
    color: white;
}


.verifiyContainer a{
    color: white;
}

.verifyCheckbox2{
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 30px;
    margin-top: 5px;
}

.verifiyContainer2{
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; 
    margin-left: 20%;*/
    margin-bottom: 10px;
    color: white;
}


.verifiyContainer2 a{
    color: white;
}

.background{
    background: url("");
    width:100%;
    height: 100%;
}

.background-home{
    width:100%;
    height: 100vh;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgb(0, 0, 0, 0.1) 90%);
    background-color: black;
}

.background-home2{
    color: rgba(0,0,0,0.5);
    width:100%;
    height: 100vh;
}

.background video{
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

.background-home video{
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

/* .logo-container{
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(0, -50%);
    width: 60vw;
    height: auto;
} */

.logo-container{
    position: absolute;
    top: 45%;
    left: 35%;
    transform: translate(0, -50%);
    width: 30vw;
    height: auto;
}

/* .logo-container{
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(0, -50%);
    width: 100%;
    height: auto;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgb(0, 0, 0, 0.1) 90%);
    padding: 50vh; 
   
    padding-right: 50em;
    padding-left: 50em;
    padding-top: 50em;
    padding-bottom: 50em;

} */

.logo-container2{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    height: auto;
    box-shadow: 50px 10px 60px 350px rgb(0, 0, 0, 0.5);
    background-color: black;

}

.logo-container2 img{
    width: 100%;
    height: auto;
    /* padding-left: 50px;
    padding-right: 50px; */
}

.logo-container p{
    color: rgb(233, 233, 233);
    text-align: center;
    font-size: 2.3vh;
    /* width: 30vw; */
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.logo-container img{
    width: 95%;
    height: auto;
    padding-left: 50px;
    padding-right: 50px;
}

.main-container{
    width:100%;
    height:600vh;
}

.promo-container{
    cursor: pointer;
    position: relative;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
}

.promo-container video{
    border: solid #505050; 
    border-radius: 20px;
    width: 100%;
}

